
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Spotify is a music streaming service that enables its users to listen to all the world's music at the press of a button as well as offering various ways to discover new music.`}</p>
    <p>{`To say I learnt a lot at Spotify would be an understatement—working here was a critical step in my growth as a developer. Before Spotify, I would never touch the command line, but now I cannot imagine my life without it, going so far as to use vim as my editor.`}</p>
    <p>{`I worked on a variety of projects—initially the marketing (spotify.com) website (using wordpress), followed by a more frontend-heavy stint implementing the now-defunct Spotify Apps API into the desktop client.`}</p>
    <p>{`This allowed me to experiment with CSS3 and HTML5 features for months while fleshing out a JavaScript API around the raw calls exposed by the client developers, offering third-party developers an awesome app-development experience.`}</p>
    <p>{`Lastly, I worked on the platform team creating the developer.spotify.com website and attending various events locally and abroad. I also did the occasional public speaking, evangelising the Spotify Apps API.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;