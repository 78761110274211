
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Label A is an agency located in the heart of Amsterdam. I worked as a lead developer on a variety of client projects using various JavaScript libraries. I played a part in standardising development on React, allowing frontend developers to be more productive.`}</p>
    <p>{`The biggest project I worked on during my time here was Grow-bee—a social network and e-learning tool aimed at businesses. It had the typical social features you'd expect, such as making posts and sharing images, but also had built in tools to create surveys.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;