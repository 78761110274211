
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Lifely is a small group of people who are ridiculously passionate about making products that simply work. Unlike many other agencies, they really form a partnership with clients to deliver something truly outstanding.`}</p>
    <p>{`I joined Lifely as a frontend developer, contributing to the development of their latest project, Part-up—a startup that enables people to create short-term, project-based teams.`}</p>
    <p>{`All development for Part-up was done in Meteor, a fairly young (at the time) full-stack JavaScript framework which also took care of build tooling.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;