
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`History`}</h2>
    <p>{`Over the past ~17 years I've held a variety of roles at companies of different sizes in a number of different sectors. I got my start at a small agency as a backend developer while I was still in school and now I'm working primarily as a front-end engineer with some ops/backend stuff mixed in at a large enterprise corporation.`}</p>
    <p>{`Below is a timeline of my career, with the big circles representing companies that have had a significant impact on my growth and career, and the smaller ones less so.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;