
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`TravelBird was a travel organisation that aimed to solve the paradox of choice when it comes to picking a vacation destination. They worked with partners situated throughout Europe to provide daily deals at excellent prices.`}</p>
    <p>{`At TravelBird I helped improve the way frontend developers worked by splitting the frontend code out of the existing monolith and instead communicating through a REST API. This allowed us to work without having to run the backend and databases locally.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;