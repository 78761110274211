
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Strangelove is a creative agency based in Amsterdam for the "always on" world. By using their business acumen they aim to provide solutions that are relevant, human-friendly, memorable and above all, simple.`}</p>
    <p>{`Working as a full-stack developer my first order of business was to give the company website a much-needed makeover. I worked closely with the motion-graphics designer and experimented a lot with parallax-effects. While building this website I even created a library called `}<a parentName="p" {...{
        "href": "https://github.com/strangelove/kubrick"
      }}>{`kubrick`}</a>{` (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Dr._Strangelove"
      }}>{`get it?`}</a>{`) which made it easier to set up such pages.`}</p>
    <p>{`After that, my work shifted towards building a CMS that could eliminate a lot of the repetitive work setting up new client websites while still allowing for customisation.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;