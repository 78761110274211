
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`At the start of my career I worked at a couple different companies trying to figure out what I liked while spending an extraordinary amount of time coding in my own time. During this time I worked at a couple of companies:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Synetic`}</strong>{`—At Synetic I worked as a backend developer, spending most of my time in PHP and MySQL working on their homegrown CMS to improve performance and functionality.`}</li>
      <li parentName="ul"><strong parentName="li">{`Submarine`}</strong>{`—At Submarine I worked as a full-stack developer, primarily creating accessible, semantically valid HTML/CSS templates with some light PHP.`}</li>
      <li parentName="ul"><strong parentName="li">{`Grrr`}</strong>{`—At Grrr I worked as a frontend developer, creating HTML/CSS templates that others would later augment with JavaScript.`}</li>
      <li parentName="ul"><strong parentName="li">{`Lucius Websystems`}</strong>{`—At Lucius I worked as a full-stack developer, creating HTML/CSS templates to be integrated into Drupal as well as coding small JavaScript components.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mediamatic`}</strong>{`—At Mediamatic I worked as a full-stack developer, setting up websites in their Anymeta CMS using prerendered templates to put them together.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;