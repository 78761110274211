
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Lemonade is a company that does insurance for the 21st century. Insurance might not sound very hip or cool, but take one look at `}<a parentName="p" {...{
        "href": "https://www.lemonade.com/"
      }}>{`the website`}</a>{` and you can immediately tell this is not your average insurance comapny.`}</p>
    <p>{`A lot of effort is invested into making the experience as seamless and easy as possible for users, from signing up for a policy to filing a claim. A significant percentage of claims is actually handled without any human intervention at all!`}</p>
    <p>{`I've only just started on my, hopefully lengthy, journey at Lemonade but I can already tell it's going to be great. Stay tuned.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;